import { Services, ServicesContext } from '@/ServicesContext'
import { HackerNewsComponent } from '@/hackernews'
import React from 'react'
import { Stack } from '@mui/joy'

export const SkippedStories: React.FC = () => {
  const { HackerNewsServices: HackerNews } = React.useContext<Services>(ServicesContext)
  const { skippedStories } = HackerNewsComponent.useSkippedStories({ repositories: HackerNews })
  const { HackerNewsServices } = React.useContext<Services>(ServicesContext)

  return (
    <Stack>
      {[...skippedStories].map((id) => (
        <HackerNewsComponent.SkippedStoryCard key={id} id={id} services={HackerNewsServices} />
      ))}
    </Stack>
  )
}
