import { Button, IconButton, Stack } from '@mui/joy'
import { ColorSchemeToggle } from '../components'
import { AppRoutes } from '../AppRoutes'
import { Star, TrendingUp, VisibilityOff } from '@mui/icons-material'
import { SharingComponents } from '@/sharing'
import React from 'react'
import { Services, ServicesContext } from '@/ServicesContext'
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'

const LINKS = [
  { label: 'Best Stories', to: AppRoutes.beststories, icon: <Star /> },
  { label: 'Top Stories', to: AppRoutes.topstories, icon: <TrendingUp /> },
  { label: 'Skipped Stories', to: AppRoutes.skippedStories, icon: <VisibilityOff /> },
]
export function NavBar() {
  const { SharingServices: Sharing } = React.useContext<Services>(ServicesContext)
  const isNotMobile = useMediaQuery('(min-width:600px)')

  const { pathname } = useLocation()

  return (
    <Stack component="nav" padding={1} direction="row">
      <Stack direction="row" role="menubar" spacing={1} flexGrow={1}>
        {LINKS.map((link) =>
          isNotMobile ? (
            <Button
              component={RouterLink}
              role="menuitem"
              to={link.to}
              variant="outlined"
              color={matchPath(link.to, pathname) ? 'primary' : 'neutral'}
              key={link.to}
              startDecorator={link.icon}
            >
              {link.label}
            </Button>
          ) : (
            <IconButton
              component={RouterLink}
              role="menuitem"
              to={link.to}
              variant={matchPath(link.to, pathname) ? 'solid' : 'outlined'}
              color={matchPath(link.to, pathname) ? 'primary' : 'neutral'}
              key={link.to}
            >
              {link.icon}
            </IconButton>
          )
        )}
      </Stack>

      <Stack direction="row" alignItems="center" spacing={1}>
        <ColorSchemeToggle />
        <SharingComponents.SharingModal sharingServices={Sharing} />
      </Stack>
    </Stack>
  )
}
