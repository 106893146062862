import { ShareOutlined } from '@mui/icons-material'
import {
  Modal,
  Sheet,
  ModalClose,
  Typography,
  IconButton,
  Button,
  Input,
  Stack,
  FormControl,
  FormLabel,
} from '@mui/joy'
import React from 'react'
import { IMaskInput } from 'react-imask'
import { CODE_LENGTH, CODE_BLOCK_ITERATION, CODE_TOTAL_LENGTH } from '../models'
import { useSharingCredential } from './useSharingCredential'
import { SharingServices } from '..'

export type SharingModalProps = {
  sharingServices: SharingServices
}

const CODE_MASK = Array(CODE_LENGTH).fill('#').join('')
const CREDENTIAL_MASK = Array(CODE_BLOCK_ITERATION).fill(CODE_MASK).join('-')

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const TextMaskAdapter = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props
  return (
    <IMaskInput
      {...other}
      definitions={{
        '#': /[a-z]/,
      }}
      mask={CREDENTIAL_MASK}
      prepareChar={(c) => c.toLocaleLowerCase()}
      inputRef={ref}
      onAccept={(_, value) => onChange({ target: { name: props.name, value: value.unmaskedValue } })}
      overwrite
    />
  )
})

export const SharingModal: React.FC<SharingModalProps> = ({ sharingServices }: SharingModalProps) => {
  const [open, setOpen] = React.useState<boolean>(false)

  const { credential } = useSharingCredential({ sharingServices })

  const [phrase, setPhrase] = React.useState<string>('')

  const changeCode = (code: string) => {
    setPhrase(code)
    if (code.length === CODE_TOTAL_LENGTH) {
      sharingServices.Sharing.activateFromPhrase(code)
      setPhrase('')
    }
  }

  return (
    <React.Fragment>
      <IconButton variant="outlined" color="neutral" onClick={() => setOpen(true)}>
        <ShareOutlined />
      </IconButton>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg',
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />

          <Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
            Link devices
          </Typography>
          {credential ? (
            <Stack spacing={2}>
              <Typography level="body-sm">Connect other devices with this code</Typography>
              <Typography level="body-lg">{credential.codes.join('-')}</Typography>
              <Button
                variant="solid"
                startDecorator={<ShareOutlined />}
                onClick={() => sharingServices.Sharing.deactivate()}
                color="danger"
              >
                Disconnect sharing
              </Button>
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Button
                variant="solid"
                startDecorator={<ShareOutlined />}
                onClick={() => sharingServices.Sharing.activate()}
              >
                Enable sharing to other devices
              </Button>
              <FormControl>
                <FormLabel>Or enter sharing code from another device</FormLabel>

                <Input
                  variant="soft"
                  size="lg"
                  color="primary"
                  value={phrase}
                  onChange={(e) => changeCode(e.target.value)}
                  slotProps={{ input: { component: TextMaskAdapter } }}
                />
              </FormControl>
            </Stack>
          )}
        </Sheet>
      </Modal>
    </React.Fragment>
  )
}
