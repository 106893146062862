import { FC, PropsWithChildren, createContext, useReducer } from 'react'

type ScrollState = {
  enabled: boolean
}

export const ScrollStateContext = createContext<ScrollState>({ enabled: true })

type ScrollControl = {
  enable: () => void
  disable: () => void
}

export const ScrollControlContext = createContext<ScrollControl>(null as unknown as ScrollControl)

const scrollControlledReducer = (_: ScrollState, action: boolean) => {
  return { enabled: action }
}

export const ScrollControlProvider: FC<PropsWithChildren> = ({ children }) => {
  const [scrollEnabled, dispatch] = useReducer(scrollControlledReducer, { enabled: true })

  const control: ScrollControl = {
    enable: () => dispatch(true),
    disable: () => dispatch(false),
  }

  return (
    <ScrollStateContext.Provider value={scrollEnabled}>
      <ScrollControlContext.Provider value={control}>{children}</ScrollControlContext.Provider>
    </ScrollStateContext.Provider>
  )
}
