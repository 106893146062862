import { Services, ServicesContext } from '@/ServicesContext'
import { HackerNewsComponent } from '@/hackernews'
import { SortedStory } from '@/hackernews/models'
import { Stack, Typography } from '@mui/joy'
import React, { useContext } from 'react'
import { ScrollControlContext, useVisible } from '.'

type StoryListProps = {
  stories: SortedStory[]
}

export const StoryList: React.FC<StoryListProps> = ({ stories }) => {
  const { HackerNewsServices } = React.useContext<Services>(ServicesContext)
  const { skippedStories } = HackerNewsComponent.useSkippedStories({ repositories: HackerNewsServices })

  const [limitTo, setLimitTo] = React.useState(10)
  const [displayedStories, setDisplayedStories] = React.useState<SortedStory[]>([])

  React.useEffect(() => {
    const result = new Array<SortedStory>()
    for (const story of stories) {
      if (result.length >= limitTo) break
      if (!skippedStories.has(story.id)) {
        result.push(story)
      }
    }
    setDisplayedStories(result)
  }, [setDisplayedStories, stories, limitTo, skippedStories])

  const ref = React.useRef<HTMLDivElement>(null)
  const { isIntersecting } = useVisible(ref)

  React.useEffect(() => {
    if (isIntersecting) {
      setLimitTo((limitTo) => limitTo + 20)
    }
  }, [isIntersecting])

  const { enable, disable } = useContext(ScrollControlContext)

  return (
    <Stack>
      {displayedStories.map((sortedStory) => (
        <HackerNewsComponent.StoryCard
          key={sortedStory.id}
          {...sortedStory}
          onSwipe={async () => disable()}
          onSwipeEnd={async () => enable()}
          services={HackerNewsServices}
        />
      ))}
      <Typography level="title-sm" ref={ref} padding={2}>
        {displayedStories[displayedStories.length - 1]?.position !== stories.length
          ? 'Loading some stories for you...'
          : 'Well, there are no more apparently.'}
      </Typography>
    </Stack>
  )
}
