import { createContext } from 'react'
import { HackerNewsServices } from '@/hackernews'
import { SharingServices } from './sharing'

export type Services = {
  readonly HackerNewsServices: HackerNewsServices
  readonly SharingServices: SharingServices
}

export const ServicesContext = createContext<Services>(undefined as unknown as Services)
