import { Services, ServicesContext } from '@/ServicesContext'
import { HackerNewsComponent } from '@/hackernews'
import React from 'react'
import { StoryList } from './components'

export const BestStories: React.FC = () => {
  const { HackerNewsServices } = React.useContext<Services>(ServicesContext)
  const { bestStories } = HackerNewsComponent.useBestStories({ repositories: HackerNewsServices })

  return <StoryList stories={bestStories} />
}
