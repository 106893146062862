import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { LoadingScreen } from '@/view/components'
import { Services, ServicesContext } from '@/ServicesContext'
import { HackerNewsDB, Firebase, IndexDB } from '@/datasources'
import { SharingRepositories, SharingUseCases } from './sharing'
import { HackerNewsRepositories } from './hackernews'

/**
 * All services are initiated and bound here
 */
async function bindServices() {
  const db = await IndexDB.loadDB()

  const sharingRepositories: SharingRepositories.Repositories = {
    SharingDataRepository: new Firebase(),
    SharingUserRepository: new IndexDB.CredentialStore(db),
  }

  const hackerNewsRepositories: HackerNewsRepositories.Repositories = {
    PersonnalRepository: new IndexDB.SkippedStoryStore(db),
    StoryRepository: new HackerNewsDB.StoryStore(),
    ItemRepository: new HackerNewsDB.ItemStore(),
  }

  const services: Services = {
    HackerNewsServices: hackerNewsRepositories,
    SharingServices: {
      ...sharingRepositories,
      Sharing: new SharingUseCases.Sharing({ hackerNewsRepositories, sharingRepositories }),
    },
  }

  services.SharingServices.Sharing.connect()

  return services
}

/**
 * Binds the services to the application and provides them to the children
 */
export const ServiceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [services, setServices] = useState<Services>()

  useEffect(() => {
    bindServices().then(setServices)
  }, [])

  return services ? <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider> : <LoadingScreen />
}
