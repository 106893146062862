import { DB } from './DB'
import { StoreNames } from 'idb'
import { Schema } from './Schema'

export abstract class Store<Name extends StoreNames<Schema>> {
  protected db: DB
  protected abstract storeName: Name

  constructor(db: DB) {
    this.db = db
  }
}
