import { HackerNewsRepositories } from '@/hackernews'
import { HackerNewsModels } from '@/hackernews'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { child, getDatabase, ref, onValue, get } from 'firebase/database'
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore'

const firebaseConfig: FirebaseOptions = {
  projectId: 'hacker-news',
  databaseURL: 'https://hacker-news.firebaseio.com',
}

const app = initializeApp(firebaseConfig, 'hacker-news')
const reference = ref(getDatabase(app))
initializeFirestore(app, {
  localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
})
const refV0 = child(reference, 'v0')

const refBestStories = child(refV0, 'beststories')
const refTopStories = child(refV0, 'topstories')

export class StoryStore implements HackerNewsRepositories.StoryRepository {
  async getBests(): Promise<string[]> {
    const snapshot = await get(refBestStories)
    return Object.keys(snapshot.val() as Record<string, unknown>)
  }

  onBestsChanges(onchange: (ids: HackerNewsModels.SortedStory[]) => Promise<void>): () => void {
    return onValue(refBestStories, (snapshot) => {
      const storyIds = Object.entries(snapshot.val() as Record<number, number>).map(([position, id]) => ({
        position: Number(position) + 1,
        id: String(id),
      }))
      onchange(storyIds)
    })
  }

  async getTops(): Promise<string[]> {
    const snapshot = await get(refTopStories)
    return Object.keys(snapshot.val() as Record<string, unknown>)
  }

  onTopsChanges(onchange: (ids: HackerNewsModels.SortedStory[]) => Promise<void>): () => void {
    return onValue(refTopStories, (snapshot) => {
      const storyIds = Object.entries(snapshot.val() as Record<number, number>).map(([position, id]) => ({
        position: Number(position) + 1,
        id: String(id),
      }))
      onchange(storyIds)
    })
  }
}

const refItem = child(refV0, 'item')

export class ItemStore implements HackerNewsRepositories.ItemRepository {
  async getItem(id: HackerNewsModels.ItemId): Promise<HackerNewsModels.Item> {
    const snapshot = await get(child(refItem, String(id)))
    return snapshot.val() as HackerNewsModels.Item
  }

  onChange(id: HackerNewsModels.ItemId, onchange: (item: HackerNewsModels.Item) => Promise<void>): () => void {
    return onValue(child(refItem, String(id)), (snapshot) => {
      onchange(snapshot.val() as HackerNewsModels.Item)
    })
  }
}
