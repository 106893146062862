import { Repositories as SharingRepositories } from '../repositories'
import { CODE_LENGTH, CODE_TOTAL_LENGTH, Credential, UserData } from '../models'
import { HackerNewsRepositories } from '@/hackernews'

type Repositories = {
  sharingRepositories: SharingRepositories
  hackerNewsRepositories: HackerNewsRepositories.Repositories
}
export class Sharing {
  readonly sharingRepositories: SharingRepositories
  readonly hackerNewsRepositories: HackerNewsRepositories.Repositories
  unsubFromSkippedStoriesAdd: (() => void) | null = null
  unsubFromSkippedStoriesRemove: (() => void) | null = null
  unsubFromUserDataChange: (() => void) | null = null

  constructor({ sharingRepositories, hackerNewsRepositories }: Repositories) {
    this.sharingRepositories = sharingRepositories
    this.hackerNewsRepositories = hackerNewsRepositories
  }

  async activate() {
    this.sharingRepositories.SharingUserRepository.setCurrentCredential(new Credential())
    return await this.connect()
  }

  deactivate() {
    this.sharingRepositories.SharingUserRepository.removeCurrentCredential()
  }

  async activateFromPhrase(phrase: string) {
    if (phrase.length !== CODE_TOTAL_LENGTH) return false
    const codes: string[] = []
    for (let i = 0; i < phrase.length; i += CODE_LENGTH) {
      codes.push(phrase.substring(i, i + CODE_LENGTH))
    }
    this.sharingRepositories.SharingUserRepository.setCurrentCredential(new Credential(codes))
    await this.connect()
  }

  async updateLocal(userData: UserData) {
    if (userData?.skippedStories) {
      await this.hackerNewsRepositories.PersonnalRepository.syncAllSkippedStories(new Set(userData.skippedStories))
    }
  }

  async connect() {
    const credential = await this.sharingRepositories.SharingUserRepository.getCurrentCredential()
    if (!credential) return false

    const result = await this.sharingRepositories.SharingDataRepository.connect(credential)
    if (result.connected) {
      await this.updateLocal(await this.sharingRepositories.SharingDataRepository.getUserData())
    } else {
      await result.createUser()

      // First sync up
      const skippedStories = await this.hackerNewsRepositories.PersonnalRepository.getSkippedStories()
      await this.sharingRepositories.SharingDataRepository.setUserData({ skippedStories: [...skippedStories] })
    }

    this.unsubFromSkippedStoriesAdd?.()
    this.unsubFromSkippedStoriesAdd = this.hackerNewsRepositories.PersonnalRepository.onSkippedStoryAdded((storyId) =>
      this.sharingRepositories.SharingDataRepository.addSkippedStory(storyId)
    )

    this.unsubFromSkippedStoriesRemove?.()
    this.unsubFromSkippedStoriesRemove = this.hackerNewsRepositories.PersonnalRepository.onSkippedStoryRemoved(
      (storyId) => this.sharingRepositories.SharingDataRepository.removeSkippedStory(storyId)
    )

    this.unsubFromUserDataChange?.()
    this.unsubFromUserDataChange = this.sharingRepositories.SharingDataRepository.onUserDataChanges(async (data) =>
      this.updateLocal(data)
    )
  }
}
