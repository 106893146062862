import React from 'react'
import { SortedStory } from '../models'
import { Repositories } from '../repositories'

export type useBestStoriesProps = {
  repositories: Repositories
}

export function useBestStories({ repositories }: useBestStoriesProps) {
  const [bestStories, setBestStories] = React.useState<SortedStory[]>([])

  React.useEffect(() => {
    const unsubscribe = repositories.StoryRepository.onBestsChanges(async (stories) => setBestStories(stories))
    return () => unsubscribe()
  }, [repositories.StoryRepository])

  return { bestStories }
}
