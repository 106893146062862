import { FirebaseApp, FirebaseError, FirebaseOptions, initializeApp } from 'firebase/app'
import { Auth, createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { SharingRepositories, SharingModels } from '@/sharing'
import {
  initializeFirestore,
  Firestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from 'firebase/firestore'
import { HackerNewsModels } from '@/hackernews'

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyDvfEuOduWUMnOG6zq2z8VSMAy_bDYkKqI',
  authDomain: 'yanhc-4331d.firebaseapp.com',
  projectId: 'yanhc-4331d',
  storageBucket: 'yanhc-4331d.appspot.com',
  messagingSenderId: '471858474403',
  appId: '1:471858474403:web:1afe540d1ee4a11991be16',
  measurementId: 'G-J097G9JMBQ',
}

type FirebaseCredantial = { email: string; password: string }

function mapCredential(credential: SharingModels.Credential): FirebaseCredantial {
  return {
    email: `${credential.codes[0]}-${credential.codes[1]}@yanhc.example`,
    password: `${credential.codes[2]}${credential.codes[3]}`,
  }
}

export class Firebase implements SharingRepositories.SharingDataRepository {
  app: FirebaseApp
  auth: Auth
  firestore: Firestore

  constructor() {
    this.app = initializeApp(firebaseConfig, 'yanhc')
    this.auth = getAuth(this.app)
    this.firestore = initializeFirestore(this.app, {
      localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
    })
  }

  protected get user() {
    return this.auth.currentUser
  }

  protected get sharingCollection() {
    return collection(this.firestore, 'sharing')
  }

  async connect(credential: SharingModels.Credential): Promise<SharingModels.ConnectionResult> {
    const { email, password } = mapCredential(credential)

    try {
      const usercredential = await signInWithEmailAndPassword(this.auth, email, password)
      console.log(`User ${this.user?.email} connected`)
      return {
        connected: true,
        removeUser: async () => {
          await usercredential.user?.delete()
        },
      }
    } catch (error) {
      return {
        connected: false,
        error: error as FirebaseError,
        createUser: async () => {
          await createUserWithEmailAndPassword(this.auth, email, password)
          console.log(`User ${this.user?.email} created`)
        },
      }
    }
  }

  async getUserData(): Promise<SharingModels.UserData> {
    if (!this.user) throw new Error('User not connected')
    const userStoreRef = doc(this.sharingCollection, this.user.uid)
    const userData = await getDoc(userStoreRef)
    return userData.data() as SharingModels.UserData
  }

  async setUserData(data: SharingModels.UserData): Promise<void> {
    if (!this.user) throw new Error('User not connected')
    const userStoreRef = doc(this.sharingCollection, this.user.uid)
    await setDoc(userStoreRef, data)
  }

  onUserDataChanges(onchange: (data: SharingModels.UserData) => Promise<void>): () => void {
    if (!this.user) throw new Error('User not connected')
    return onSnapshot(doc(this.sharingCollection, this.user.uid), (doc) => {
      onchange(doc.data() as SharingModels.UserData)
    })
  }

  async addSkippedStory(storyId: HackerNewsModels.ItemId): Promise<void> {
    if (!this.user) throw new Error('User not connected')
    const userStoreRef = doc(this.sharingCollection, this.user.uid)
    await updateDoc(userStoreRef, {
      skippedStories: arrayUnion(storyId),
    })
  }

  async removeSkippedStory(storyId: HackerNewsModels.ItemId): Promise<void> {
    if (!this.user) throw new Error('User not connected')
    const userStoreRef = doc(this.sharingCollection, this.user.uid)
    await updateDoc(userStoreRef, {
      skippedStories: arrayRemove(storyId),
    })
  }
}
