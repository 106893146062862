import React from 'react'
import { SortedStory } from '../models'
import { Repositories } from '../repositories'

export type useTopStoriesProps = {
  repositories: Repositories
}

export function useTopStories({ repositories }: useTopStoriesProps) {
  const [topStories, setTopStories] = React.useState<SortedStory[]>([])

  React.useEffect(() => {
    const unsubScribe = repositories.StoryRepository.onTopsChanges(async (stories) => {
      setTopStories(stories)
    })

    return () => {
      unsubScribe()
    }
  }, [repositories.StoryRepository])

  return { topStories }
}
