import React from 'react'
import { ItemId } from '../models'
import { Grid, IconButton, Sheet, Typography } from '@mui/joy'
import { Remove } from '@mui/icons-material'
import { useStory } from './useStory'
import { HackerNewsServices } from '..'

type StoryCardProps = {
  id: ItemId
  services: HackerNewsServices
}

export const SkippedStoryCard: React.FC<StoryCardProps> = ({ id, services }: StoryCardProps) => {
  const { story, unSkip } = useStory({ repositories: services, id })

  return (
    story && (
      <Sheet variant="outlined">
        <Grid container spacing={1} sx={{ flexGrow: 1, padding: 1, alignItems: 'center' }}>
          <Grid xs="auto">
            <IconButton onClick={() => unSkip()}>
              <Remove />
            </IconButton>
          </Grid>

          <Grid xs={2} sx={{ flexGrow: 1 }}>
            <Sheet>
              <Typography level="title-md">{story.title}</Typography>
            </Sheet>
          </Grid>
        </Grid>
      </Sheet>
    )
  )
}
