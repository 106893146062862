import React from 'react'
import ReactDOM from 'react-dom/client'
import { StyledEngineProvider } from '@mui/joy/styles'
import App from '@/view/App'
import { ServiceProvider } from '@/ServiceProvider'
import { CssVarsProvider } from '@mui/joy/styles'
import CssBaseline from '@mui/joy/CssBaseline'
import '@fontsource/inter'
import { globalStyles, theme } from './theme'
import { registerSW } from 'virtual:pwa-register'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        {globalStyles}
        <ServiceProvider>
          <App />
        </ServiceProvider>
      </CssVarsProvider>
    </StyledEngineProvider>
  </React.StrictMode>
)

registerSW({ immediate: true })
