import { CssVarsThemeOptions, GlobalStyles, extendTheme } from '@mui/joy'

export const globalStyles = (
  <GlobalStyles
    styles={{
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
      },
    }}
  />
)
export const theme = extendTheme({
  NavBar: {
    maxHeight: '64px',
  },
} as CssVarsThemeOptions)
