import { Store } from './Store'
import { HackerNewsModels, HackerNewsRepositories } from '@/hackernews'

export class SkippedStoryStore extends Store<'skippedStories'> implements HackerNewsRepositories.PersonnalRepository {
  protected storeName = 'skippedStories' as const
  static currentId = 'current' as const

  addListeners = new Set<(skippedStory: HackerNewsModels.ItemId) => Promise<void>>()
  removeListeners = new Set<(skippedStory: HackerNewsModels.ItemId) => Promise<void>>()

  async getSkippedStories(): Promise<Set<HackerNewsModels.ItemId>> {
    return new Set((await this.db.getAll(this.storeName)).map(({ id }) => id))
  }

  async addSkippedStory(storyId: HackerNewsModels.ItemId): Promise<void> {
    let id = String(storyId)
    if (await this.db.get(this.storeName, id)) return
    id = await this.db.put(this.storeName, { id })
    this.addListeners.forEach((onChange) => onChange(id))
  }

  async removeSkippedStory(storyId: HackerNewsModels.ItemId): Promise<void> {
    const id = String(storyId)

    if (!(await this.db.get(this.storeName, id))) return
    await this.db.delete(this.storeName, id)
    this.removeListeners.forEach((onChange) => onChange(id))
  }

  onSkippedStoryAdded(onchange: (story: HackerNewsModels.ItemId) => Promise<void>): () => void {
    this.addListeners.add(onchange)
    return () => this.addListeners.delete(onchange)
  }
  onSkippedStoryRemoved(onchange: (story: HackerNewsModels.ItemId) => Promise<void>): () => void {
    this.removeListeners.add(onchange)
    return () => this.removeListeners.delete(onchange)
  }

  async syncAllSkippedStories(sourceStories: Set<HackerNewsModels.ItemId>): Promise<void> {
    const localSkipped = await this.getSkippedStories()

    const toRemove = new Set(localSkipped)
    for (const storyId of sourceStories) {
      toRemove.delete(storyId)
      await this.addSkippedStory(storyId)
    }

    for (const storyId of toRemove) {
      await this.removeSkippedStory(storyId)
    }
  }
}
