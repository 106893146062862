import React from 'react'

import { Credential } from '../models'
import { SharingServices } from '..'

export type UseSharingProps = {
  sharingServices: SharingServices
}

export const useSharingCredential = ({ sharingServices }: UseSharingProps) => {
  const [credential, setCredential] = React.useState<Credential | null>(null)

  React.useEffect(() => {
    sharingServices.SharingUserRepository.getCurrentCredential().then((credential) => setCredential(credential))
    const unsubcribe = sharingServices.SharingUserRepository.onCurrentCredentialChanges(async (credential) =>
      setCredential(credential)
    )
    return () => {
      unsubcribe()
    }
  }, [sharingServices.SharingUserRepository])

  return { credential }
}
