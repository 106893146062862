import { Services, ServicesContext } from '@/ServicesContext'
import { HackerNewsComponent } from '@/hackernews'
import React from 'react'
import { StoryList } from './components'

export const TopStories: React.FC = () => {
  const { HackerNewsServices } = React.useContext<Services>(ServicesContext)
  const { topStories } = HackerNewsComponent.useTopStories({ repositories: HackerNewsServices })

  return <StoryList stories={topStories} />
}
