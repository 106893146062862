import React from 'react'
import { ItemId } from '../models'
import { Grid, IconButton, Link, Sheet, Skeleton, Stack, Typography } from '@mui/joy'
import { OpenInBrowserOutlined, VisibilityOffOutlined } from '@mui/icons-material'
import { useStory } from './useStory'
import { HackerNewsServices } from '..'
import { HackerNewsIcon } from './HackerNewsIcon'
import ReactHtmlParser from 'react-html-parser'
import { useMediaQuery } from '@mui/material'

const SWIPE_MINIMAL_DISTANCE = 20
const SWIPE_VERTICAL_LIMIT = 50
const SWIPE_WIDTH_THRESHOLD = 100
const SWIPE_END_ZONE = 20
type SwipeVector = { x: number; y: number }

const vectorNull = (): SwipeVector => ({ x: 0, y: 0 })

type UseSwipeProps = {
  onSwipe?: (vector: SwipeVector) => Promise<void>
  onSwipeEnd?: (vector: SwipeVector, success: boolean) => Promise<void>
}
const useSwipe = ({ onSwipe, onSwipeEnd }: UseSwipeProps) => {
  const [swipeVector, setSwipeVector] = React.useState<SwipeVector>(vectorNull())
  const [startSwipeEvent, setStartSwipeEvent] = React.useState<React.TouchEvent<HTMLDivElement> | null>(null)

  const inSkipZone = swipeVector.x > SWIPE_WIDTH_THRESHOLD - SWIPE_END_ZONE

  const setStartPoint = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault()
    resetSwipe()
    setStartSwipeEvent(event)
  }

  const resetSwipe = () => {
    setSwipeVector(vectorNull())
    setStartSwipeEvent(null)
  }

  const swipe = (event: React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (!startSwipeEvent) return
    const vector = {
      x: event.changedTouches[0].clientX - startSwipeEvent?.changedTouches[0]?.clientX,
      y: event.changedTouches[0].clientY - startSwipeEvent?.changedTouches[0]?.clientY,
    }

    if (Math.abs(vector.y) > SWIPE_VERTICAL_LIMIT) {
      resetSwipe()
      return
    }

    if (vector.x > SWIPE_WIDTH_THRESHOLD) vector.x = SWIPE_WIDTH_THRESHOLD
    if (vector.x < SWIPE_MINIMAL_DISTANCE) return

    onSwipe?.(vector)
    setSwipeVector(vector)
  }

  const endSwipe = async () => {
    onSwipeEnd?.(swipeVector, inSkipZone)
    resetSwipe()
  }

  return { swipe, endSwipe, swipeVector, setStartPoint, inSkipZone }
}

type StoryCardProps = UseSwipeProps & {
  id: ItemId
  position: number
  services: HackerNewsServices
}

export const StoryCard: React.FC<StoryCardProps> = ({
  id,
  position,
  services,
  onSwipe,
  onSwipeEnd,
}: StoryCardProps) => {
  const { story, firstComment, skip } = useStory({ repositories: services, id })
  const [folded, setFolded] = React.useState<boolean>(true)

  const { swipeVector, swipe, endSwipe, setStartPoint, inSkipZone } = useSwipe({
    onSwipeEnd: async (_, success) => {
      if (success) skip()
      onSwipeEnd?.(swipeVector, success)
    },
    onSwipe,
  })

  const isNotMobile = useMediaQuery('(min-width:600px)')
  const supportTouch = window.matchMedia('(pointer: coarse)').matches
  const displayVisibilityButton = isNotMobile || !supportTouch

  return (
    <Sheet
      variant="outlined"
      sx={{
        left: `${swipeVector.x}px`,
        transition: swipeVector.x === 0 ? 'left 0.3s' : 'none',
      }}
      onTouchMove={(event) => swipe(event)}
      onTouchEnd={() => endSwipe()}
      onTouchStart={(event) => setStartPoint(event)}
      onClick={() => setFolded(!folded)}
    >
      <Stack
        justifyContent="center"
        padding={1}
        color={inSkipZone ? 'success' : undefined}
        sx={(theme) => ({
          position: 'absolute',
          top: 0,
          left: `-${swipeVector.x}px`,
          height: '100%',
          width: `${SWIPE_WIDTH_THRESHOLD}px`,
          borderRight: `${SWIPE_END_ZONE}px solid ${
            inSkipZone ? theme.palette.success.softColor : theme.palette.neutral.softColor
          }`,
          zIndex: -1,
        })}
      >
        <VisibilityOffOutlined color={inSkipZone ? 'success' : undefined} />
      </Stack>

      <Grid
        container
        padding={0}
        spacing={1}
        sx={{
          flexGrow: 1,
          padding: 1,
          alignItems: 'center',
        }}
      >
        {displayVisibilityButton && (
          <Grid xs="auto">
            <IconButton onClick={() => skip()}>
              <VisibilityOffOutlined />
            </IconButton>
          </Grid>
        )}

        <Grid xs="auto">
          <Typography level="title-sm" color="neutral" paddingRight={1}>
            #{position}
          </Typography>
        </Grid>

        <Grid xs={2} sx={{ flexGrow: 1 }}>
          <Typography level="title-md">
            <Skeleton loading={!story?.title}>{story && story.title}</Skeleton>
          </Typography>
        </Grid>

        <Grid xs="auto">
          {story?.url && (
            <Link href={story?.url}>
              <IconButton>
                <OpenInBrowserOutlined />
              </IconButton>
            </Link>
          )}
          <Skeleton loading={!story?.id}>
            {story && (
              <Link href={`https://news.ycombinator.com/item?id=${story.id}`}>
                <IconButton>
                  <HackerNewsIcon />
                </IconButton>
              </Link>
            )}
          </Skeleton>
        </Grid>

        {!folded && firstComment && <Grid xs={12}>{ReactHtmlParser(firstComment?.text)}</Grid>}
      </Grid>
    </Sheet>
  )
}
