import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/joy'
import React from 'react'
import LoadingImage from '@/assets/loading.jpeg'

export const LoadingScreen: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        width: '100vw',
        right: 0,
        top: 0,
        bottom: 0,
        transition: 'background-image var(--Transition-duration), left var(--Transition-duration) !important',
        transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
        backgroundColor: 'background.level1',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${LoadingImage})`,
      }}
    >
      <Card>
        <CardContent>
          <Typography typography="title-sm" startDecorator={<CircularProgress variant="plain" thickness={2} />}>
            Loading...
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}
