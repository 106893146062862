import React, { Fragment } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { Box } from '@mui/joy'
import { Footer } from './parts/Footer'
import { NavBar } from './parts/NavBar'
import { AppRoutes } from './AppRoutes'
import { BestStories } from './BestStories'
import { TopStories } from './TopStories'
import { SkippedStories } from './SkippedStories'
import { ScrollControlProvider, ScrollStateContext } from './components'

const MainBox: React.FC = () => {
  const { enabled } = React.useContext(ScrollStateContext)

  return (
    <Box
      component="main"
      position="relative"
      flexGrow={1}
      sx={{ minWidth: '100vw', overflowY: enabled ? 'scroll' : 'hidden', overflowX: 'clip' }}
    >
      <Routes>
        <Fragment>
          <Route path={AppRoutes.topstories} element={<TopStories />} />
          <Route path={AppRoutes.skippedStories} element={<SkippedStories />} />
          <Route path={AppRoutes.beststories} element={<BestStories />} />
        </Fragment>
      </Routes>
    </Box>
  )
}

const App: React.FC = () => {
  return (
    <Router>
      <NavBar />

      <ScrollControlProvider>
        <MainBox />
      </ScrollControlProvider>

      <Footer />
    </Router>
  )
}

export default App
