import { Item, ItemId } from '../models'
import { Repositories } from '../repositories'
import React from 'react'

export type useStoryProps = {
  repositories: Repositories
  id: ItemId
  hotReload?: boolean
}

export function useStory({ repositories, id, hotReload }: useStoryProps) {
  const [story, setStory] = React.useState<Item | null>(null)
  const [firstComment, setFirstComment] = React.useState<Item | null>(null)

  React.useEffect(() => {
    repositories.ItemRepository.getItem(id).then((story) => setStory(story))

    const unsubScribe = hotReload
      ? repositories.ItemRepository.onChange(id, async (story) => setStory(story))
      : () => {}

    return () => {
      unsubScribe()
    }
  }, [hotReload, id, repositories.ItemRepository])

  React.useEffect(() => {
    if (story && story.kids && story.kids.length > 0) {
      repositories.ItemRepository.getItem(story.kids[0]).then((comment) => setFirstComment(comment))
    }
  }, [story, repositories.ItemRepository])

  const skip = async () => {
    if (story) {
      await repositories.PersonnalRepository.addSkippedStory(story.id)
    }
  }

  const unSkip = async () => {
    if (story) {
      await repositories.PersonnalRepository.removeSkippedStory(story.id)
    }
  }

  return { story, firstComment, skip, unSkip }
}
