import { SharingRepositories } from '@/sharing'
import { SharingModels } from '@/sharing'
import { Store } from './Store'
import { StoreCredential } from './Schema'

function toStoreCredential(credential: SharingModels.Credential): StoreCredential {
  return { id: CredentialStore.currentId, codes: credential.codes }
}

function toCredential(storeCredential: StoreCredential): SharingModels.Credential {
  return new SharingModels.Credential(storeCredential.codes)
}

export class CredentialStore extends Store<'credential'> implements SharingRepositories.SharingUserRepository {
  protected storeName = 'credential' as const
  static currentId = 'current' as const

  listeners = new Set<(credential: SharingModels.Credential | null) => Promise<void>>()

  async removeCurrentCredential(): Promise<void> {
    await this.db.delete(this.storeName, CredentialStore.currentId)
    this.listeners.forEach((onChange) => onChange(null))
  }

  async setCurrentCredential(credential: SharingModels.Credential): Promise<void> {
    await this.db.put(this.storeName, toStoreCredential(credential))
    this.listeners.forEach((onChange) => onChange(credential))
  }

  async getCurrentCredential(): Promise<SharingModels.Credential | null> {
    const credential = await this.db.get(this.storeName, CredentialStore.currentId)
    return credential ? toCredential(credential) : null
  }

  onCurrentCredentialChanges(onchange: (credential: SharingModels.Credential | null) => Promise<void>): () => void {
    this.listeners.add(onchange)
    return () => this.listeners.delete(onchange)
  }
}
