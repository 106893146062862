import { openDB, IDBPDatabase } from 'idb'

import { Schema } from './Schema'

export type DB = IDBPDatabase<Schema>

export async function loadDB(): Promise<DB> {
  return openDB<Schema>('db', 1, {
    upgrade(db, oldVersion) {
      if (oldVersion <= 0) {
        db.createObjectStore('credential', { keyPath: 'id' })
        db.createObjectStore('skippedStories', { keyPath: 'id' })
      }
    },
  })
}
