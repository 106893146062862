import React from 'react'
import { ItemId } from '../models'
import { Repositories } from '../repositories'

export type useSkippedStoriesProps = {
  repositories: Repositories
}

export function useSkippedStories({ repositories }: useSkippedStoriesProps) {
  const [skippedStories, setSkippedStories] = React.useState<Set<ItemId>>(new Set())

  React.useEffect(() => {
    repositories.PersonnalRepository.getSkippedStories().then((storyIds) => setSkippedStories(storyIds))

    const unsubscribeAdd = repositories.PersonnalRepository.onSkippedStoryAdded(async (storyId) => {
      setSkippedStories((stories) => new Set([...stories, storyId]))
    })

    const unsubscribeRemove = repositories.PersonnalRepository.onSkippedStoryRemoved(async (storyId) => {
      setSkippedStories((stories) => {
        const newStories = new Set(stories)
        newStories.delete(storyId)
        return newStories
      })
    })

    return () => {
      unsubscribeAdd()
      unsubscribeRemove()
    }
  }, [repositories.PersonnalRepository])

  return { skippedStories }
}
