import React from 'react'
import { RefObject } from 'react'

export const useVisible = (ref: RefObject<HTMLElement>) => {
  const [isIntersecting, setIntersecting] = React.useState(false)

  const observer = React.useMemo(() => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)), [])

  React.useEffect(() => {
    if (ref.current) observer.observe(ref.current)
    return () => observer.disconnect()
  }, [observer, ref])

  return { isIntersecting }
}
